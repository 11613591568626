<template>
    <b-modal 
        id="payment-method-modal" 
        :no-close-on-backdrop="true"
        hide-header 
        hide-footer 
        centered
        size="lg"
        @show="onShow"
        @hidden="onHidden"
    >
        <div class="modal-header">
            <p class="modal-title">{{ form.id ? 'Atualizar' : 'Adicionar' }} método de pagamento / recebimento</p>
            <Close class="icon" @click="close" />
        </div>
        <main class="content">
            <b-row>
                <b-col class="col-12">
                    <b-form-group>
                        <label for="payment_method_name">Nome</label>
                        <b-form-input
                            autocomplete="off"
                            id="payment_method_name"
                            v-model="form.name"
                            placeholder="Nome do método de pagamento / recebimento"
                        />
                        <div v-if="validated && !form.name" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group> 
                </b-col>
                <b-col class="col-6">
                    <b-form-group>
                        <label>Tipo</label>
                        <Autocomplete
                            v-model="form.type"
                            :options="types"
                            @input="changeType"
                        />
                        <div v-if="validated && !form.type" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group>
                </b-col>
                <b-col class="col-6">
                    <b-form-group>
                        <label for="machine_name">Pag/Receb automático?</label>
                        <Autocomplete
                            v-model="form.change"
                            :options="changes"
                        />
                        <div v-if="validated && !form.change" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group> 
                </b-col>

                <b-col v-if="['card', 'debit_card'].includes(form.type)" class="col-6">
                    <b-form-group>
                        <label for="machine_name">Bandeira</label>
                        <Autocomplete
                            v-model="form.card_flag_id"
                            :options="cardFlags"
                        />
                        <div v-if="validated && (['card', 'debit_card'].includes(form.type) && !form.card_flag_id)" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group> 
                </b-col>
                <b-col v-if="['card', 'debit_card'].includes(form.type)" class="col-6">
                    <b-form-group>
                        <label for="machine_name">Máquina</label>
                        <Autocomplete
                            v-model="form.card_machine_id"
                            :options="cardMachines"
                        />
                        <div v-if="validated && (['card', 'debit_card'].includes(form.type) && !form.card_machine_id)" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group> 
                </b-col>
                
                <b-col v-if="['card', 'debit_card'].includes(form.type)" class="col-6">
                    <b-form-group>
                        <label>Antecipação ativa?</label>
                        <Select
                            v-model="form.anticipation"
                            :options="anticipations"
                            :disabled="form.type === 'debit_card' || (!!form.id && form.anticipation !== null)"
                        />
                        <div v-if="validated && !!form.anticipation" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group>
                </b-col>
                <b-col v-if="['card', 'debit_card'].includes(form.type)" class="col-6">
                    <b-form-group>
                        <label for="receipt_delay">Atraso de recebimento</label>
                        <b-form-input
                            autocomplete="off"
                            id="receipt_delay"
                            type="number"
                            v-model="form.receipt_delay"
                            placeholder="1 dia"
                            min="0"
                            max="30"
                        />
                        <div v-if="validated && form.receipt_delay && (parseInt(form.receipt_delay) < 0 || parseInt(form.receipt_delay) > 30)" class="custom-invalid-feedback">Informe um valor entre 0 e 30</div>
                    </b-form-group> 
                </b-col>
                <b-col class="col-12">
                    <b-form-group>
                        <label>Conta</label>
                        <Autocomplete
                            v-model="form.bank_account_id"
                            :options="bankAccounts"
                        />
                        <div v-if="validated && !form.bank_account_id" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="mt-2" v-if="['debit_card','card'].includes(form.type)">
                <b-col class="col-12">                    
                    <div v-if="form.type === 'debit_card'" class="rules-details">
                        Neste cenário, o recebimento {{ !!form.receipt_delay ? `terá um atraso de ${form.receipt_delay} dia(s)` : 'não terá atraso' }}. Podendo ser adiado para o próximo dia útil.
                    </div>
                    <div v-if="form.type === 'card' && form.anticipation === true" class="rules-details">
                        Neste cenário, o recebimento será a vista {{ !!form.receipt_delay ? `com um atraso de ${form.receipt_delay} dia(s)` : 'e não terá atraso' }}. Podendo ser adiado para o próximo dia útil.
                    </div>
                    <div v-if="form.type === 'card' && form.anticipation === false" class="rules-details">
                        Neste cenário, o recebimento será parcelado com atraso de 1 mês {{ !!form.receipt_delay ? `+ ${form.receipt_delay} dia(s)` : '' }}. Podendo ser adiado para o próximo dia útil.
                    </div>
                </b-col>
                <b-col class="col-12">
                    <div class="obs-rules">Estas regras não se aplicam para o uso deste método como pagamento</div>
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col class="col-6">
                    <Check
                        v-model="form.default_payment_method_entries"
                        label="Método padrão para entradas"
                    />
                </b-col>
                <b-col class="col-6">
                    <Check
                        v-model="form.default_payment_method_outband"
                        label="Método padrão para saídas"
                    />
                </b-col>
            </b-row>

            <b-table-simple v-if="!['cash', 'pix', 'debit'].includes(form.type)">
                <thead>
                    <tr>
                        <td class="td-head">Parcelas</td>
                        <td class="td-head">Taxa/Valor</td>
                        <td class="td-head"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(installment, index) in form?.installments" :key="index">
                        <td>
                            <b-form-input
                                class="installment-position"
                                :value="form.type !== 'debit_card' ? `Parcelamento ${index + 1}x` : 'Débito a vista'"
                                disabled
                            />
                        </td>
                        <td>
                            <money
                                class="form-control installment-value"
                                :prefix="['card', 'debit_card'].includes(form.type) ? '% ' : 'R$ '"
                                autocomplete="off"
                                v-model="installment.value"
                            />
                        </td>
                        <td>
                            <div class="actions-wrapper">
                                <Delete 
                                    v-if="index !== 0"
                                    class="delete-icon"
                                    @click="removeInstallment(index)"
                                />
                                <Plus 
                                    v-if="form.type !== 'debit_card' && index === (form.installments.length - 1)"
                                    class="plus-icon"
                                    @click="addInstallment"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </main>
        <div class="wrapper-button">
            <b-button
                variant="outlined-default"
                @click="close"
            >
                Cancelar
            </b-button>
            <b-button
                variant="primary"
                :disabled="loading"
                :loading="loading"
                @click="savePaymentMethod"
            >
                Salvar
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
    props: {
        paymentMethod: Object,
        cardFlags: Array,
        cardMachines: Array,
        bankAccounts: Array,
    },
    components: {
        Plus: () => import('@/assets/icons/plus.svg'),
        Close: () => import('@/assets/icons/close.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        Check: () => import('@/components/General/Check'),
        Autocomplete: () => import('@/components/Autocomplete'),
        Select: () => import('@/components/Select'),
    },
    data() {
        return {
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            form: this.getDefaultForm(),
            initialInstallmentIds: [],
            types: [
                { label: 'Dinheiro', value: 'cash' },
                { label: 'Cartão de crédito', value: 'card' },
                { label: 'Cartão de débito', value: 'debit_card' },
                { label: 'PIX', value: 'pix' },
                { label: 'Débito em conta', value: 'debit' },
                { label: 'Boleto', value: 'bank_slip' },
                { label: 'Cheque', value: 'check' },
            ],
            changes: [
                { label: 'Selecionar...', value: null },
                { label: 'Sim', value: 'auto' },
                { label: 'Não', value: 'manual' }
            ],
            anticipations: [
                { label: 'Não', value: false },
                { label: 'Sim', value: true },
            ]
        }
    },
    methods: {
        onShow() {
            this.form = this.paymentMethod ? { ...this.paymentMethod } : this.getDefaultForm()
            if(!this.paymentMethod?.installments?.length) this.form.installments = [{ position: 0, value: 0 }]
            else this.initialInstallmentIds = this.form.installments.map(x => x.id)

        },
        getDefaultForm: () => ({
            name: '',
            type: 'cash',
            change: null,
            card_flag_id: null,
            card_machine_id: null,
            anticipation: false,
            receipt_delay: null,
            bank_account_id: null,
            default_payment_method_entries: false,
            default_payment_method_outband: false,
            installments: [{ position: 0, value: 0 }],
        }),
        close() {
            this.$bvModal.hide('payment-method-modal')
            this.$emit('hidden')
        },
        onHidden() {
            this.form = this.getDefaultForm()
            this.validated = false
        },
        changeType(){
            if (this.form.type === 'debit_card' && this.form.installments.length > 1) {
                this.form.anticipation = false
                this.form.installments = [this.form.installments[0]]
            }
        },
        addInstallment() {
            const lastPosition = this.form.installments[this.form.installments.length - 1];
            let value = 0;
            if (this.form.type !== 'card') {
                value = lastPosition.value;
            }
            this.form.installments.push({ position: lastPosition.position + 1, value })
        },
        async removeInstallment(index) {
            if(this.form?.installments[index]?.id){
                await this.api.deletePaymentMethodInstallment(this.form.installments[index].id);
            }
            this.form.installments.splice(index, 1);
        },
        isValidForm(){
            this.validated = true
            if(this.form.type === 'cash' || this.form.type === 'pix' || this.form.type === 'debit') {
                return this.form?.type &&
                this.form?.name &&
                this.form?.change &&
                this.form?.bank_account_id
            }
            return this.form?.type &&
            this.form?.name &&
            this.form?.change &&
            this.form?.bank_account_id &&
            this.form?.installments.length &&
            (!['card', 'debit_card'].includes(this.form?.type) || this.form?.card_flag_id) &&
            (!['card', 'debit_card'].includes(this.form?.type) || this.form?.card_machine_id) &&
            (!['card', 'debit_card'].includes(this.form?.type) || this.form?.anticipation !== null) &&
            (!['card', 'debit_card'].includes(this.form?.type) || !this.form?.receipt_delay || (parseInt(this.form?.receipt_delay) >= 0 && parseInt(this.form?.receipt_delay) <= 30))
        },
        getFinalInstallments() {
            if (['cash', 'pix', 'debit'].includes(this.form.type))
                this.form.installments = []
            else if (['debit_card'].includes(this.form.type)) 
                this.form.installments = [this.form.installments[0]]
            this.form.installments.forEach(this.updateIndex)
            return this.form.installments
        },
        updateIndex(item, index, arr) {
            item.index = index
            arr[index] = item
        },
        async savePaymentMethod() {
            if (!this.isValidForm()) return;
            const data = {
                clinic_id: this.clinic.id,
                name: this.form.name,
                type: this.form.type,
                change: this.form.change,
                card_flag_id: this.form.card_flag_id,
                card_machine_id: this.form.card_machine_id,
                anticipation: this.form.anticipation,
                receipt_delay: this.form.receipt_delay,
                bank_account_id: this.form.bank_account_id,
                default_payment_method_entries: this.form.default_payment_method_entries,
                default_payment_method_outband: this.form.default_payment_method_outband,
                installments: this.getFinalInstallments(),
            }
            this.form.id ?
                await this.updatePaymentMethod(this.form.id, data) :
                await this.createPaymentMethod(data);
            this.$emit('update-table')
        },
        async createPaymentMethod(data) {
            if (!this.isValidForm()) return
            this.loading = true
            try {
                await api.createPaymentMethod(data)
                this.$toast.success('Método cadastrado com sucesso!')
                this.close()
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        async updatePaymentMethod(id, data) {
            if (!this.isValidForm()) return
            this.loading = true
            try {
                await api.updatePaymentMethod(id, data)
                this.$toast.success('Método atualizado com sucesso!')
                this.close()
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
    },
    watch: {
        paymentMethod(val) {
            this.form = val ? { ...val } : this.getDefaultForm()
            if(!val?.installments?.length) this.form.installments = [{ position: 0, value: 0 }]
        },
        'form.type': function() {
            if (this.form.type === 'debit_card' && this.form.installments.length > 1) {
                this.form.anticipation = false
                this.form.installments = [this.form.installments[0]]
            }
        }
    }
}
</script>

<style lang="scss">
    #payment-method-modal {
    .modal-content {
        border-radius: 8px;

        .modal-body {
        padding: 0 !important;
        margin: 0 !important;

            .modal-header {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px !important;

                .modal-title {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: var(--type-active);
                }

                .icon {
                width: 25px;
                fill: var(--neutral-500);
                }
            }

            table {
                border: none;
                margin-bottom: 0;
                margin-top: 1rem;

                .td-head {
                    color: var(--dark-blue);
                    border: none;
                    background-color: var(--neutral-100);
                    font-weight: 600;
                    font-size: 14px;
                }

                td {
                    vertical-align: middle;
                }
            }

            .close {
                height: 24px;
                width: 24px;
                fill: var(--neutral-500);
            }

            .actions-wrapper {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            .plus-icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                fill: var(--blue-500);
                cursor: pointer;
            }

        .delete-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .content {
                padding: 2rem;
            }

            .wrapper-button {
                width: 100%;
                display: inline-flex;
                padding: 0  2rem 1rem 2rem;
                justify-content: flex-end;
                gap: 2rem;
            }
        }
    }
    .rules-details {
        padding: 10px;
        font-size: 14px;
        border: 1px solid var(--blue-500);
        color: var(--blue-500);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .obs-rules {
        font-size: 12px;
        color: var(--type-active);
        padding: 5px;
        text-align: center;
    }
}
</style>